<template>
    <CCard>
        <CCardHeader>
            <strong>Inventory</strong>
        </CCardHeader>
        <CCardBody>
            <InventoryReportTable :items="$store.state.inventory.inventory"/>
        </CCardBody>
    </CCard>
</template>

<script>
import InventoryReportTable from "./InventoryReportTable"
export default {
    components: {
        InventoryReportTable
    },
    created(){
        this.$store.dispatch('inventory/fetchInventory');
    }
}
</script>

<style>

</style>