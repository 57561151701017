<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :dark="dark"
        size="sm"
      >
        <template #brand="{item}">
          <td>
            {{item.item.brand}}
          </td>
        </template>
        <template #particular="{item}">
          <td>
            {{item.item.particular}}
          </td>
        </template>
        <template #unit="{item}">
          <td>
            {{item.item.item_variations[0].unit.name}}
          </td>
        </template>
        <template #unit_franchise_cost="{item}">
          <td>
            ₱{{item.item.item_variations[0].franchise_cost.toLocaleString()}}
          </td>
        </template>
        <template #total_franchise_cost="{item}">
          <td>
            ₱{{totalCost(item)}}
          </td>
        </template>
        <template #unit_wg_cost="{item}">
          <td>
            ₱{{item.item.item_variations[0].wg_cost.toLocaleString()}}
          </td>
        </template>
        <template #total_wg_cost="{item}">
          <td>
            ₱{{totalWGCost(item)}}
          </td>
        </template>
        <template #onhand="{item}">
          <td>
            {{item.available_qty}}
          </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import moment from 'moment';
import RoleModal from "@/containers/RoleModal"

export default {
  name: 'Table',
  components: {
    RoleModal
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
            { key: 'brand', _style:'width:20%' },
            { key: 'particular', _style:'width:40%' },
            { key: 'unit', _style:'width:10%' },
            { key: 'onhand', _style:'width:10%' },
            { key: 'unit_wg_cost', label: 'Unit Cost(Wash&Go)', _style:'width:10%' },
            { key: 'total_wg_cost', label: 'Total Cost(Wash&Go)', _style:'width:10%' },
            { key: 'unit_franchise_cost', label: 'Unit Cost(Franchise)', _style:'width:10%' },
            { key: 'total_franchise_cost', label: 'Total Cost(Franchise)', _style:'width:10%' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  filters: {
      formatChange(data){
          return moment(String(data)).format('Y-MM-D');
      },
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    totalCost(value){
      var total_cost = value.item.item_variations[0].franchise_cost * value.available_qty;
      return total_cost.toLocaleString()
    },
    totalWGCost(value){
      var total_cost = value.item.item_variations[0].wg_cost * value.available_qty;
      return total_cost.toLocaleString()
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
    getValueCar(data){
      this.$emit('event_child', data, 'car');
    },
  }
}
</script>
